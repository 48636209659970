<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"/>

    <edit-field
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/staffmanage/UpdateStaff"
      finish-router="staff"
      permission="Sta_Edit"
      :submit-action="submitAction"
    >
      <template #facility="scope">
        <SlotFacilityRoleIDs
          :company-id="scope.rowData.companyID"
          :row-data="scope.rowData"
          @onRoleListChange="onRoleListChange"/>
      </template>

      <template v-slot:loginNfcs="scope">
        <field-login-nfcs
          :is-edit="scope.isEdit"
          :root-item="scope.rootItem"
          :items="row_data['loginNfcs']"
          :show-add-btn="true"
          :show-delete-btn="true"
          @updateValues="(value) => row_data['loginNfcs'] = value"/>
      </template>

      <template v-slot:callBellNfcs="scope">
        <field-modal-call-bell-nfcs
          :is-edit="scope.isEdit"
          :row-data="scope.rowData"
          :root-item="scope.rootItem"
          :items="row_data['callBellNfcs']"
          :facilityAndRoleList="facilityAndRoleList"
          @updateValues="(value) => row_data['callBellNfcs'] = value"
          @onUpdateIsLoginNfc="onUpdateIsLoginNfc"/>
      </template>

    </edit-field>
  </page-content>
</template>

<script>

import fields, { reloadFieldData } from '@/views/staff/fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import PageContent from '@/components/PageContent'
import SlotFacilityRoleIDs from '@/views/staff/SlotFacilityRoleIDs'
import SlotFloor from '@/views/bed/SlotFloor'
import FieldLoginNfcs from './FieldLoginNfcs.vue'
import FieldModalCallBellNfcs from './FieldModalCallBellNfcs.vue'
import staffFormMixin from "@/views/staff/staffFormMixin"
import _ from 'lodash'

export default {
  name: 'Edit',
  components: {
    SlotFloor,
    SlotFacilityRoleIDs,
    PageContent,
    EditFieldHeader,
    EditField,
    fields,
    FieldLoginNfcs,
    FieldModalCallBellNfcs,
  },
  mixins: [staffFormMixin],
  data() {
    return {
      console,
      title: common.getMenuName('staff') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      facilityAndRoleList: [],
      row_data: {},
      orig_data: {},
    }
  },
  computed: {
    loginNfcs() {
      if (this.row_data.loginNfcs?.length >= 1) return this.row_data.loginNfcs
      return []
    },
    // callBellNfcs() {
    //   if (this.row_data.facilityNfcs?.length >= 1) {
    //     return this.row_data.facilityNfcs.map(obj => {
    //       return obj.facilityName ? `${obj.nfcTag} (${obj.facilityName})` : obj.nfcTag
    //     })
    //   }
    //   return []
    // },
  },
  created() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }

    reloadFieldData()
    common.apiGetData('/staffmanage/GetStaff?staffID=' + this.id)
      .then(({ data }) => {
        const orig_data = {
          ...data.staff,
          loginNfcs: data.staff.loginNfcs.map(n => n.toUpperCase()),
          callBellNfcs: data.callBellNfcs.map(nObj => ({
            ...nObj,
            serialNumber: nObj.serialNumber.toUpperCase(),
          })),
        }
        this.row_data = _.cloneDeep(orig_data)
        this.orig_data = _.cloneDeep(orig_data)
      })
  },
  methods: {
    onRoleListChange(facilityAndRoleList, { callBellFacilitys }) {
      this.facilityAndRoleList = facilityAndRoleList

      // 動態顯示緊急鍵 NFC
      const isShowCallBellNfc = !!(callBellFacilitys && callBellFacilitys.length > 0)
      this.fields.filter(f => ['callBellNfcTitle', 'callBellNfcs'].includes(f.name))
        .forEach(f => f.edit_show = isShowCallBellNfc)

      // 啓用了緊急鍵的 facility
      this.row_data.callBellFacilitys = [...callBellFacilitys] ?? []
    },
    onUpdateIsLoginNfc(value, nfc) {
      const loginNfcs = this.row_data['loginNfcs']
      if (value && !loginNfcs.includes(nfc)) {
        this.row_data['loginNfcs'].push(nfc)
      }
      if (!value && loginNfcs.includes(nfc)) {
        this.row_data['loginNfcs'] = loginNfcs.filter(n => n !== nfc)
      }
    },
    submitAction(rowData, api, finishRouter) {
      const that = this

      // 檢查特定的編輯字段
      const fieldsToConfirm = []
      if (!_.isEqual(this.orig_data.loginNfcs, this.row_data.loginNfcs)) {
        fieldsToConfirm.push(common.getI18n('staff.login_nfc'))
      }
      if (!_.isEqual(this.orig_data.callBellNfcs, this.row_data.callBellNfcs)) {
        fieldsToConfirm.push(common.getI18n('staff.call_bell_nfc'))
      }

      // 不包含特定字段，直接提交
      if (fieldsToConfirm.length === 0) return submit()

      // 包含特定字段，提示確認
      common.showConfirm({
        title: common.getI18n('common.form_fields_edit_confirm_tip'),
        html: fieldsToConfirm.map(f => `<span>${f}</span>`).join(`;<br />`),
        confirm_fun: () => submit()
      })

      function submit() {
        that.$requestWehealth({ method: 'post', url: api, data: rowData })
          .then(() => that.$router.push({ name: finishRouter }))
          .catch((err) => that.handleSubmitError(err, that.row_data.callBellFacilitys))
      }
    },
  },
}
</script>

<style scoped>

</style>
